import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FaTimes } from 'react-icons/fa';

export default function Model({ isOpen, closeModal, title, children }) {
    return (
        <Fragment>
            <Transition appear show={isOpen} >
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child

                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >

                                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                    <button
                                        type="button"
                                        className="fixed top-6 right-6 inline-flex z-10 justify-center rounded-md border border-transparent bg-gray-100 border-gray-300 px-2 py-2 text-sm font-medium text-blue-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 hover:border-gray-400 focus-visible:ring-offset-2"
                                        onClick={closeModal}
                                    >
                                        <FaTimes size={20} className='text-gray-500' />
                                    </button>
                                    {title && (
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6  text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                    )}


                                    {children && (
                                        <div>
                                            {children}
                                        </div>
                                    )}

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}
