import { createElement, Fragment } from 'react'

const Stack = ({ stack }) => {
    return (
        <Fragment>
            <div className={`flex items-center bg-slate-200 space-x-1  text-gray-800 text-sm font-medium px-3 py-1 rounded-full cursor-pointer hover:shadow-md ${stack?.class} `}>
                <div>
                    {createElement(stack?.icon, { size: stack?.size || 20 })}
                </div>
                <div>
                    {stack?.title}
                </div>
            </div>
        </Fragment>
    )
}

export default Stack