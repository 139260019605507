import { technologies } from 'data/constants'
import { Fragment, useState } from 'react'
import { removeHtmlTags } from 'utilities/str'
import placholder from '../../assets/images/no-image.jpeg'
import Stacks from '../stacks'
import Model from '../ui/model'

const Project = ({ project, id }) => {
    const [isOpen, setIsOpen] = useState(false)
    const stacks = technologies.filter(item => project?.technologies.includes(item?.id))

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <Fragment>
            <div className=" bg-white rounded-lg  shadow-md dark:bg-gray-800 dark:border-gray-700">
                <div className='aspect-w-4 aspect-h-2 '>
                    <img className="rounded-t-lg object-cover m-auto" src={project?.cover || placholder} alt="" />
                </div>
                <div className="p-5">

                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {project?.title}
                    </h5>

                    <p className='text-justify line-clamp-3'>{removeHtmlTags(project?.description)}</p>


                    <Stacks stacks={stacks} limit={3} />

                    <button onClick={openModal} className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white btn-primary rounded-lg focus:ring-4 focus:outline-none focus:ring-orange-300 ">
                        Read more
                        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>
            </div>

            <Model isOpen={isOpen} closeModal={closeModal} >


                <div className='aspect-w-4 aspect-h-2'>
                    <img src={project?.cover || placholder} alt="" className='object-cover' />
                </div>

                <div className='p-6'>

                    <h3 className='text-3xl mb-3'>{project?.title}</h3>
                    <div
                        className="project-description"
                        dangerouslySetInnerHTML={{ __html: project?.description }}
                    />
                    <div className="mt-4">
                        <h3 className='font-medium mb-3'>Technology Used :</h3>
                        <Stacks stacks={stacks} />
                    </div>
                </div>
            </Model>
        </Fragment >
    )
}

export default Project