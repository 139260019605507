import { menuList } from "data/constants"

const Footer = () => {
    return (

        <footer className="p-4 bg-white shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="/" className="hover:underline">Ajay Kumar</a>. All Rights Reserved.
            </span>
            <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">

                {menuList.map((item, idx) => (
                    <li key={idx}>
                        <a href={item?.href} className="mr-4 hover:underline md:mr-6 ">{item?.label}</a>
                    </li>
                ))}

            </ul>
        </footer>

    )
}

export default Footer