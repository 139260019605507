import { Fragment } from "react"
import bg from '../assets/images/my-bg.jpg'
import { Header } from "./layouts"

const Banner = () => {
    return (
        <Fragment>
            <div className="w-full bg-cover" style={{
                backgroundImage: "url(" + bg + ")"
            }} id='home'>
                <Header />
                <div className="container mx-auto px-6 sm:px-8">
                    <div className="grid grid-cols-2 gap-10">
                        <div className="py-10 col-span-2 md:text-center">
                            <h3 className="text-primary font-medium text-2xl mb-2">Hello, I'm</h3>

                            <h1 className="text-5xl font-medium mb-3">Ajay Kumar</h1>
                            <h3 className="text-xl font-medium mb-6">A Fullstack Developer</h3>
                            <p className="font-light mb-3 text-center">
                                I am a dedicated full-stack developer driven by a fervent passion for tackling complex enterprise challenges through my coding expertise. My approach revolves around crafting intricate yet intuitive solutions that deliver seamless user experiences. With a strong proficiency in developing a diverse range of applications, including CRMs, E-commerce platforms, mobile applications, and websites, I am committed to pushing the boundaries of web development to create innovative and user-friendly interfaces.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Banner