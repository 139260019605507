import emailjs from "@emailjs/browser"
import emailConfig from "data/email.config"
import { createElement, Fragment, useState } from "react"
import { MdEmail, MdPhone } from 'react-icons/md'
import bg from '../assets/images/contact-bg.png'


const ContactMe = () => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    })
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const onChange = (e) => setValues({ ...values, [e.target.name]: e.target.value })

    const tiles = [
        {
            title: 'Email',
            subtitle: 'ajaykumarr.dev@gmail.com',
            icon: MdEmail,
            class: 'bg-green-200',
            iconColor: 'text-green-500'
        },
        {
            title: 'Phone',
            subtitle: '+91 7895035044',
            icon: MdPhone,
            class: 'bg-purple-200',
            iconColor: 'text-purple-500'
        }
    ]

    const handleSendMail = (e) => {
        e.preventDefault()
        setLoading(true)
        setSuccess(false)
        emailjs.send(emailConfig.service_id, emailConfig.temaplate_id, values, emailConfig.public_key)
            .then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    setValues({ name: '', email: '', phone: '', subject: '', message: '' })
                    setSuccess(true)
                }
            }, (_) => {
                setLoading(false)
                console.log('Something went wrong');
            });
    }


    return (
        <Fragment>
            <div className="w-full bg-contain bg-no-repeat bg-slate-50 py-10" style={{
                backgroundImage: "url(" + bg + ")"
            }} id='contact-me'>
                <div className='container mx-auto py-10 px-6 sm:px-8'>
                    <div className='mb-10 sm:mb-20'>
                        <h2 className='text-center text-3xl font-medium mb-3'>Contact Me</h2>
                        <hr className='border border-primary mb-4 w-60 mx-auto' />
                        <p className='text-center'>
                            Please fill out the form on this section to contact with me. Or call between 9:00 a.m. and 8:00 p.m. IST
                        </p>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">

                        <div className="flex justify-center items-center">
                            <div className="flex flex-col space-y-4">
                                {tiles.map((item, idx) => (
                                    <Fragment key={idx}>
                                        <div className="flex items-center space-x-6">
                                            <div className={`rounded-full h-20 w-20 flex items-center justify-center ${item?.class}`}>
                                                {createElement(item?.icon, { size: 24, className: item?.iconColor })}
                                            </div>
                                            <div>
                                                <h3 className="font-semibold text-2xl mb-1">{item?.title}</h3>
                                                <h6 className="text-lg text-gray-500">{item?.subtitle}</h6>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))}
                            </div>
                        </div>

                        <div>

                            {success && (
                                <div className="border border-green-400 bg-green-200 text-green-500 rounded-md px-6 py-3 mb-6">
                                    Thanks for contacting me, I will reach you soon
                                </div>
                            )}

                            <form onSubmit={handleSendMail} className="space-y-6">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                                    <div className="form-group">
                                        <label htmlFor="name">Your Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            value={values?.name}
                                            onChange={onChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="name">Your Email</label>
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            value={values?.email}
                                            onChange={onChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div className="form-group">
                                        <label htmlFor="phone">Your Phone</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={values?.phone}
                                            className="form-control"
                                            onChange={onChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="name">Subject</label>
                                        <input
                                            type="text"
                                            name="subject"
                                            value={values?.subject}
                                            className="form-control"
                                            onChange={onChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 gap-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Message</label>
                                        <textarea name="message" id="" cols="30" rows="5" onChange={onChange} required
                                            value={values?.message}
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 gap-6">
                                    <button type='submit' disabled={loading} className="btn-primary text-white">
                                        {loading ? 'Sending...' : 'Send'}
                                    </button>
                                </div>
                            </form>


                        </div>

                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default ContactMe