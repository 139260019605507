import bg from 'assets/images/skill-bg.png'
import { technologies } from 'data/constants'
import { createElement, Fragment } from 'react'

const MySkills = () => {

    const skills = [
        {
            title: 'Languages',
            items: [1, 2, 5, 16, 12]
        },
        {
            title: 'Frameworks/Libraries',
            items: [3, 4, 6, 7, 9, 10, 13, 15, 17]
        },
        {
            title: 'Databases',
            items: [11, 14]
        },
    ]

    return (
        <div className="w-full bg-contain bg-right bg-no-repeat py-10" style={{
            backgroundImage: "url(" + bg + ")"
        }} id='my-skills'>
            <div className="container mx-auto px-6 sm:px-8 py-10">
                <div className='mb-10 sm:mb-20'>
                    <h2 className='text-center text-3xl font-medium mb-3'>My Skills</h2>
                    <hr className='border border-primary mb-4 w-60 mx-auto' />
                    <p className='text-center'>
                        My projects encompass a wide array of technologies, spanning across frontend, backend, and database development.
                    </p>
                </div>

                <div className="flex flex-col gap-10">
                    {
                        skills.map((skill, idx) => (
                            <Fragment key={idx}>
                                <div className='w-full rounded-md'>
                                    <h3 className='text-2xl mb-2'>{skill.title}</h3>
                                    <hr className='border border-gray-600 mb-6 w-20' />
                                    <div className='grid grid-cols-2 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-6 flex-wrap w-full justify-center'>
                                        {skill.items.map((id, idx) => {
                                            const item = technologies.find(i => i.id === id)

                                            return (
                                                <div key={idx} className='text-center'>
                                                    <div className={'skill-box ' + item?.class}>
                                                        <div className="flex flex-col space-y-4 justify-between items-center mb-1">
                                                            <span className={`text-sm font-medium h-20`}>
                                                                {createElement(item.icon, { size: item.size * 5 || 80 })}
                                                            </span>
                                                            <span className={`text-base font-medium `}>
                                                                {item.title}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Fragment>
                        ))
                    }


                </div>

            </div>
        </div >
    )
}

export default MySkills