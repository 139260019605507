import { Fragment } from "react"
import Stack from "./Stack"

const Stacks = ({ stacks, limit = 0 }) => {
    const length = limit ? limit : stacks.length

    return (
        <Fragment>
            <div className='flex flex-wrap gap-2 items-center mb-3'>
                {stacks.map((stack, idx) => {
                    if (idx < length) {
                        return (
                            <Fragment key={idx}>
                                <Stack stack={stack} />
                            </Fragment>
                        )
                    } else {
                        return <Fragment key={idx}></Fragment>
                    }
                })}
                {limit && limit !== stacks.length ? (
                    <span className="text-sm text-gray-500 mb-1">& more</span>
                ) : ('')}
            </div>
        </Fragment>
    )
}

export default Stacks