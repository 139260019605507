import { Banner } from 'components'
import ContactMe from 'components/ContactMe'
import { Footer } from 'components/layouts'
import MySkills from 'components/MySkills'
import MyStacks from 'components/MyStacks'
import Projects from 'components/projects'
import { Fragment } from 'react'

const App = () => {
	return (
		<Fragment>
			<div className='w-full'>
				<Banner />
				<MyStacks />
				<Projects />
				<MySkills />
				<ContactMe />
				<Footer />
			</div>
		</Fragment>
	)
}

export default App