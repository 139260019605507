import { projects } from 'data/constants'
import { Fragment } from 'react'
import project from '../../assets/images/porject-bg.png'
import Project from './Project'

const Projects = () => {

    return (
        <div className="w-full bg-no-repeat bg-slate-50 py-10" style={{
            backgroundImage: "url(" + project + ")"
        }} id='my-portfolio'>
            <div className='container mx-auto px-6 sm:px-8 py-10'>
                <div className='mb-10 sm:mb-20'>
                    <h2 className='text-center text-3xl font-medium mb-3'>My Portfolio</h2>
                    <hr className='border border-primary mb-4 w-60 mx-auto' />
                    <p className='text-center'>
                        Please review my exemplary projects spanning diverse industries, stacks, and technologies.
                    </p>
                </div>

                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8'>
                    {projects.map((project, idx) => {
                        return (
                            <Fragment key={idx}>
                                <Project project={project} id={idx} />
                            </Fragment>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Projects