import { stacks } from "data/constants"
import { createElement, Fragment } from "react"

const MyStacks = () => {

    return (
        <div className="w-full py-10" id="my-stacks">
            <div className="container mx-auto px-6 sm:px-8 my-10">
                <div className='mb-10 sm:mb-20'>
                    <h2 className='text-center text-3xl font-medium mb-3'>My Stacks</h2>
                    <hr className='border border-primary mb-4 w-60 mx-auto' />
                    <p className='text-center'>
                        Below are some of the popular stacks in which I excel, showcasing my core strengths within each one.
                    </p>
                </div>
                <div className="grid justify-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 ">
                    {stacks.map((stack, idx) => {
                        return (
                            <Fragment key={idx}>
                                <div className="flex flex-col p-5 text-center">
                                    <div className="mx-auto mb-3">
                                        {createElement(stack.icon, { size: 40 })}
                                    </div>
                                    <h3 className="text-2xl mb-4">{stack.title}</h3>
                                    <p className="font-light text-base text-gray-500">{stack.description}</p>
                                </div>
                            </Fragment>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default MyStacks